.index-contactUs {
  width: 100%;
  max-width: 1367px;
  margin: 0 auto;
  background-color: #f8fdff;
  padding: 1em;
  display: flex;
  flex-direction: column;
  font-family: iran-sans-b;

  .banner {
    img {
      width: 100%;
    }
  }

  .information-work {
    display: flex;
  }

  .col-form {
    width: 62%;
  }

  .col-information {
    width: 38%;
    padding: 1em;
    margin: 1em 2em;
    border-right: 2px solid rgb(206, 206, 206);
    color: #1d4c61;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .work-time {
    .content {
      font-size: 14px;
      margin: 0.5em 0;
      display: flex;
      font-family: iran-sans-r;
    }
  }

  form {
    background-color: #fff;
    width: 100%;
    box-shadow: 0 0 5px rgba(194, 194, 194, 0.5);
    margin: 0.5em 1em;
    border-radius: 10px;
    padding: 1em;

    .row {
      width: 100%;
      display: flex;
    }
    .col {
      width: 50%;
      display: flex;
      flex-direction: column;
      margin: 0 0.2em;
      padding: 0.5em 0;
    }
    .col-100 {
      width: 99%;
      display: flex;
      flex-direction: column;
      margin: 0 0.2em;
      padding: 0.5em 0;
    }
    label {
      font-size: 14px;
      font-family: iran-sans-r;
      color: #1d4c61;
    }
    input {
      padding: 0.5em 1em;
      margin: 0.3em 0;
      border-radius: 5px;
      border: 1px solid rgb(206, 206, 206);
      font-family: iran-sans-r;
    }
    textarea {
      padding: 0.5em 1em;
      margin: 0.3em 0;
      border-radius: 5px;
      border: 1px solid rgb(206, 206, 206);
    }
    button {
      width: 40%;
      padding: 0.7em 2em;
      border-radius: 10px;
      background-color: #00aeb4;
      color: #fff;
      font-family: iran-sans-b;
      border: none;
      cursor: pointer;
    }
  }
}

@media (max-width: 670px) {
  .index-contactUs .information-work {
    flex-direction: column;
  }
  .index-contactUs .col-form {
    width: 90%;
  }
  .index-contactUs form .row {
    flex-direction: column;
  }
  .index-contactUs form .col {
    width: 99%;
  }
}