.container{
    margin: auto;
    width: 70%;
    .titelcontainer{
        margin-top: 25px;
        display: flex;
        justify-content: center;
        .titel{
            margin: auto;
            text-align: center;
        }
    }
    .discontain{
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        width: 100%;
        span{
            margin: 10px 0;
        }
    }
    .imagecontainer{ 
        margin-top: 30px;
        width: 100%;
        display: flex;
        justify-content: center;
    }
    img{
        max-width: 50%;
        border-radius: 1em;

    }
}
@media (max-width:600px) {
    .container{
        margin: auto;
        width: 95%;
        .titelcontainer{

            .titel{
                font-size: 20px;
                margin: auto;
                text-align: center;
            }
        }
        .discontain{

            span{
                font-size: 14px;

                margin: 5px 0;
            }
        }
        img{
            max-width: 80%;
            border-radius: 1em;
    
        }
    }

}