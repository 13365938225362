.index-agent {
  width: 100%;
  max-width: 1367px;
  margin: 0 auto;
  background-color: #f8fdff;
  padding: 1em;
  display: flex;
  flex-direction: column;
  font-family: iran-sans-b;

  .agentBanner {
    width: 100%;
  }
  .agent-about {
    display: flex;
  }
  .content {
    width: 70%;
    padding: 1em;

    h1 {
      font-size: 16px;
      padding: 1em 0;
    }
    p {
      font-family: iran-sans-r;
      font-size: 14px;
      text-align: justify;
    }
  }
  .map {
    .title {
      width: 100%;
      display: flex;
      justify-content: center;
      color: #7d7d7d;
      position: relative;
      top: 50px;
    }
  }
  .agent-list {
    background-color: #fff;
    box-shadow: 0 0 5px rgba(194, 194, 194, 0.5);
    border-radius: 10px;
    padding: 1em;
    margin-top: 2em;

    label {
      display: flex;
      justify-content: center;
      color: #7d7d7d;
    }
    table {
      width: 100%;
      overflow: hidden;
      td {
        text-align: center;
        padding: 0.7em;
      }
      hr {
        width: 56110%;
        opacity: 0.5;
      }
    }
  }
  .submit-agent {
    button {
      width: 100%;
      padding: 1em 2em;
      border-radius: 10px;
      background-color: #00aeb4;
      color: #fff;
      font-family: iran-sans-b;
      border: none;
      cursor: pointer;
      margin: 1em 0;
    }
  }
}
