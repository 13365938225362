.loader {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(61, 57, 59, 0.8);
}

.pin1 {
  position: absolute;
  top: -120%;
  left: -120%;

  border-radius: 50% 50% 50% 0;
  border: 4px solid rgb(255, 0, 0);
  width: 40px;
  height: 40px;
  transform: rotate(-45deg);
}

.pin1::after {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 50%;
  top: 35%;
  left: 35%;
  margin-left: -5px;
  margin-top: -5px;
  background-color: rgb(255, 0, 0);
}

.cover {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 110;
  top: 0;
  display: none;
}

.index-product {
  width: 100%;
  max-width: 1367px;
  margin: 0 auto;
  background-color: #f8fdff;
  padding: 1em;
  display: flex;
  font-family: iran-sans-b;
  display: flex;
  flex-direction: column;

  a {
    cursor: zoom-in;
  }

  .map {
    color: #707070;
    font-size: 14px;
    span {
      cursor: pointer;
      transition: color .5s ease;
      &:hover {
        color: #007579;
      }
    }
  }

  .product {
    padding: 1em 0 0 0;
    display: flex;
    width: 100%;
    font-family: iran-sans-b;
/* * {
} */

    .img {
      width: 30%;

      img {
        box-shadow: 0 0 5px rgba(194, 194, 194, 0.5);
        border-radius: 10px;
        width: 100%;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      width: 48%;
      padding: 0.5em;
      color: #1d4c61;
    }

    .subTitle {
      font-size: 12px;
    }

    hr {
      margin: 0.5em 0;
      opacity: 0.5;
    }

    .price-section {
      display: flex;
      flex-direction: column;
      width: 25%;

      .content {
        background-color: #fff;
        width: 100%;
        margin: 0.2em 0;
        box-shadow: 0 0 5px rgba(194, 194, 194, 0.5);
        border-radius: 10px;
        padding: 0.5em 1em;
      }

      .point {
        display: flex;
        padding: 0.5em 0 0 0;
        justify-content: space-between;
      }

      .self {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .percent {
          background-color: #f6931e;
          color: #fff;
          border-radius: 5px;
          padding: 0.2em 0.5em;
          margin: 0 0.5em 0 0;
        }
        .main-price {
          text-decoration: line-through;
          opacity: 0.6;
        }
      }

      .off {
        display: flex;
        justify-content: flex-end;
        margin: 0.5em 0;
        margin: 0.5em 0;
      }
    }

    .price {
      padding: 0.2em 0;
    }

    .mark {
      display: flex;

      img {
        width: 60px;
      }
      .title {
        display: flex;
        flex-direction: column;
        font-size: 15px;
        padding: 0.2em 0.5em;
        color: #1d4c61;
      }
    }

    .payButton {
      padding: 0.8em 0;
      margin: 0.8em 0;
      font-family: iran-sans-b;
      border: 0;
      border-radius: 5px;
      cursor: pointer;
      color: #fff;
      background-color: #f6931e;
    }

    .thumbnails {
      display: flex;
      overflow: auto;
      width: 28%;

      img {
        width: 100px;
        margin: 0 0.2em;
        cursor: zoom-in;
      }
    }

    ::-webkit-scrollbar {
      width: 0; /* Remove scrollbar space */
      background: transparent; /* Optional: just make scrollbar invisible */
      height: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background: #1d4c61;
    }
  }

  .support {
    width: 70%;
    margin: 0 auto 0 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    background-color: #02a1a7;
    padding: 1em;
    border-radius: 10px;

    .payment-icon {
      position: relative;
      top: 4px;
    }
    img {
      width: auto;
      border: 0;
      box-shadow: 0 0 0 0;
    }
  }

  .comment {
    display: flex;
    width: 100%;
    margin: 2em 0;

    .labels {
      width: 12%;

      .label {
        padding: 0.8em 0.7em;
        border: 1px solid #007579;
        border-left: 0;
        cursor: pointer;
      }
      .label:nth-child(1) {
        border-radius: 0 10px 0 0;
      }
      .label:nth-child(2) {
        border-top: 0;
        border-bottom: 0;
      }
      .label:nth-child(3) {
        border-radius: 0 0 10px 0;
      }

      .active {
        background-color: #00aeb4;
        color: #fff;
      }
    }

    .details {
      white-space: pre-line;
      width: 88%;
      font-family: iran-sans-r;
      font-size: 14px;
      padding: 1em 1.5em;
      border: 1px solid #007579;
      border-radius: 10px 0 10px 10px;
      text-align: justify;
    }

    .payWay {
      width: 88%;
      font-family: iran-sans-r;
      font-size: 14px;
      padding: 1em 1.5em;
      border: 1px solid #007579;
      border-radius: 10px 0 10px 10px;
      text-align: justify;
      display: none;
    }

    .comments {
      width: 88%;
      font-family: iran-sans-r;
      font-size: 14px;
      padding: 1em 1.5em;
      border: 1px solid #007579;
      border-radius: 10px 0 10px 10px;
      display: none;

      .add-comment {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .content {
          display: flex;
          flex-direction: column;
          padding: 1em 0;

          span:nth-child(1) {
            font-family: iran-sans-b;
          }
          span:nth-child(2) {
            font-size: 12px;
          }
        }
        button {
          height: 40px;
          padding: 0 3em;
          font-family: iran-sans-b;
          border: 0;
          border-radius: 5px;
          cursor: pointer;
          background-color: #00aeb4;
          color: #fff;
        }
      }

      .comment {
        display: flex;
        flex-direction: column;
        margin: 1em 0;

        .row {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .row:nth-child(2) {
          margin: 0.5em 0;
        }
        .content {
          display: flex;
          flex-direction: column;
        }
        .status-success {
          background-color: #d8ffe5;
          padding: 0.5em 2em;
          border-radius: 10px;
          color: #19b542;
        }
        .status-danger {
          background-color: #ffdcdc;
          padding: 0.5em 2em;
          border-radius: 10px;
          color: #ff5858;
        }

        hr {
          margin: 0.5em 0 0 0;
        }
        span:nth-child(2) {
          font-family: iran-sans-b;
        }
      }
    }
  }
}

.signInCard {
  background-color: #fff;
  position: fixed;
  font-family: iran-sans-b;
  z-index: 120;
  width: 50%;
  max-width: 700px;
  top: 50px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  padding: 1em 1.5em;
  display: flex;
  align-items: center;

  .signInPic {
    width: 30%;
    padding: 1em;
  }
  .content {
    width: 70%;
    display: flex;
    flex-direction: column;
    padding: 1em;
  }
  .title {
    color: #1d4c61;
    margin: 0 0 1em 0;
  }
  input {
    padding: 0.6em;
    border: 1px solid rgb(158, 158, 158);
    border-radius: 5px;
    font-family: iran-sans-b;
    margin: 0.5em 0;
  }
  button {
    padding: 0.7em 2em;
    border-radius: 5px;
    background-color: #00aeb4;
    color: #fff;
    font-family: iran-sans-b;
    border: none;
    cursor: pointer;
    height: 40px;
  }
  a {
    display: flex;
    margin: 1em 0;
    font-size: 14px;
  }
  small {
    color: red;
    position: relative;
    top: -5px;
    display: none;
  }
  .buttonText {
    justify-content: center;
  }
  .buttonSpin {
    display: none;

    svg {
      margin: 0 auto;
    }
  }
}

.forgetPassword {
  background-color: #fff;
  position: fixed;
  font-family: iran-sans-b;
  z-index: 120;
  width: 50%;
  max-width: 700px;
  top: 50px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  padding: 1em 1.5em;
  display: flex;
  align-items: center;

  .signInPic {
    width: 30%;
    padding: 1em;
  }
  .content {
    width: 70%;
    display: flex;
    flex-direction: column;
    padding: 1em;
  }
  .title {
    color: #1d4c61;
    margin: 0 0 1em 0;
  }
  input {
    padding: 0.6em;
    border: 1px solid rgb(158, 158, 158);
    border-radius: 5px;
    font-family: iran-sans-b;
    margin: 0.5em 0;
  }
  button {
    padding: 0.7em 2em;
    border-radius: 5px;
    background-color: #00aeb4;
    color: #fff;
    font-family: iran-sans-b;
    border: none;
    cursor: pointer;
    height: 40px;
  }
  a {
    display: flex;
    margin: 1em 0;
    font-size: 14px;
  }
  .buttonText {
    justify-content: center;
  }
  .buttonSpin {
    display: none;

    svg {
      margin: 0 auto;
    }
  }
}

.submitComment,
.deleteForm {
  background-color: #fff;
  position: fixed;
  font-family: iran-sans-b;
  z-index: 120;
  width: 50%;
  max-width: 700px;
  top: 50px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  padding: 1em 1.5em;
  display: none;

  .title {
    color: #1d4c61;
    text-align: center;
  }
  .point {
    padding: 0.5em 0;
    display: flex;
    justify-content: space-between;
    font-family: iran-sans-r;
    color: #1d4c61;
  }
  .openion {
    display: flex;
    flex-direction: column;
    padding: 0.5em 0;
    font-family: iran-sans;

    input {
      padding: 0.7em;
      margin: 0.2em 0;
      border: 1px solid rgb(197, 197, 197);
      border-radius: 5px;
      font-family: iran-sans-r;
    }
    select {
      padding: 0.4em;
      margin: 0.2em 0;
      border: 1px solid rgb(197, 197, 197);
      border-radius: 5px;
      font-family: iran-sans-r;
    }
    textarea {
      padding: 0.7em;
      margin: 0.2em 0;
      border: 1px solid rgb(197, 197, 197);
      border-radius: 5px;
      font-family: iran-sans;
    }
  }
  hr {
    opacity: 0.5;
  }
  .suggest {
    display: flex;
    width: 90%;
    justify-content: space-between;
    align-items: center;
    color: #1d4c61;
    font-size: 14px;
    font-family: iran-sans-r;

    span:nth-child(1) {
      font-size: 16px;
      font-family: iran-sans-b;
    }
    input {
      margin: 0 0.5em;
    }
  }
  .rule {
    margin: 0.5em 0;

    input {
      margin: 0 0 0 0.5em;
      position: relative;
      top: 2px;
    }
    a {
      color: #1d4c61;
      font-family: iran-sans-r;
      font-size: 14px;
    }
  }
  .buttons {
    button {
      cursor: pointer;
      padding: 0.5em 4.5em;
      font-family: iran-sans-b;
      border: 0;
      border-radius: 5px;
      font-size: 15px;
    }

    button:nth-child(1) {
      background-color: #00aeb4;
      color: #fff;
      margin: 0 0 0.5em 0.5em;
      border: 1px solid #00aeb4;
    }
    button:nth-child(2) {
      border: 1px solid #00aeb4;
      color: #00aeb4;
      background-color: #fff;
    }
  }
}

@media (max-width: 670px) {
  .index-product .product {
    flex-direction: column;
  }
  .index-product .product .content {
    width: 100% !important;
    position: relative;
    top: 100px;
  }
  .index-product .product .price-section {
    width: 100% !important;
    position: relative;
    top: 100px;

    .content {
      top: 0;
    }
  }
  .index-product .product .thumbnails {
    width: 100% !important;
    position: relative;
    top: -400px;
  }
  .index-product .support {
    width: 100%;
    margin-top: 65px;
  }
  .index-product .comment {
    flex-direction: column;
  }
  .index-product .comment .labels {
    width: 100%;
  }
  .index-product .comment .labels {
    display: flex;
    justify-content: space-around;
  }
  .index-product .comment .labels .label {
    border-radius: 5px !important;
    margin: 0.5em 0;
    border: 1px solid #00aeb4;
  }
  .index-product .comment .details {
    width: 100%;
    border: 0;
  }
  .index-product .comment .comments {
    width: 100%;
    border: 0;
  }
  .index-product .comment .payWay {
    width: 100%;
    border: 0;
  }
  .submitComment {
    width: 80%;
  }
  .submitComment .suggest {
    display: flex;
    flex-direction: column;
    padding: 0.5em;
    width: 100%;
  }
  .submitComment .suggest span {
    padding: 0.5em;
  }
  .buttons {
    display: flex;
    flex-direction: column;
  }
  .submitComment .buttons button:nth-child(1) {
    margin: 0 0 0.5em 0;
  }
  .index-product .comment .labels .label:nth-child(2) {
    border-top: 1px solid #007579;
  }
  .index-product .comment .labels .label:nth-child(2) {
    border-bottom: 1px solid #007579;
  }
  .index-product .comment .comments .add-comment {
    flex-direction: column;
  }
  .index-product .comment .comments .add-comment button {
    margin: 0.5em;
  }
  .index-product .comment .comments .comment .status-success {
    font-size: 12px;
  }
  .index-product .comment .comments .comment .status-danger {
    font-size: 12px;
  }
}

@media (max-width: 390px) {
  .submitComment .openion label {
    font-size: 11px;
  }
  .submitComment .point span:nth-child(1) {
    font-size: 14px;
  }
  .index-product .comment .comments .add-comment {
    flex-direction: column;
  }
  .index-product .comment .comments .add-comment button {
    margin: 0.5em;
  }
  .submitComment {
    top: 25px;
  }
  .submitComment .suggest span:nth-child(1) {
    font-size: 12px;
    padding: 0.2em;
  }
  .submitComment .suggest span {
    padding: 0.2em;
    font-size: 12px;
  }
  .submitComment .rule a {
    font-size: 10px;
  }
  .index-product .comment .labels .label {
    font-size: 14px;
  }
  .index-product .comment .comments .add-comment {
    flex-direction: column;
  }
  .index-product .comment .comments .comment .row {
    flex-direction: column;
  }
  .index-product .comment .comments .comment .content span:nth-child(1) {
    margin: 0 auto;
  }
  .index-product .comment .labels .label:nth-child(2) {
    border-top: 1px solid #00aeb4;
    border-bottom: 1px solid #00aeb4;
  }
}
