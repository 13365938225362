.index-main {
  width: 100%;
  max-width: 1367px;
  margin: 0 auto;
  background-color: #f8fdff;
  padding: 1em;

  .swiper-mobile {
    display: none
  }

  .search-mobile {
    display: none;
  }
  .swiper {
    width: 100%;

    .swiper-button-next::after {
      color: #fff;
      font-size: 25px;
      margin-left: 20px;
    }
    .swiper-button-prev::after {
      color: #fff;
      font-size: 25px;
      margin-right: 20px;
    }

    .slide-img {
      width: 100%;
    }

    img {
      border-radius: 10px;
    }
  }
  .special-offers {
    width: 99%;
    margin: 10px auto;
    padding: 1em;
    background: #f6931e;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .right {
      position: relative;
      width: 20%;

      .specialOfferImage {
        position: relative;
        top: -30px;
        right: 10px;
      }

      img {
        position: relative;
        margin-bottom: 25px;
      }

      .all-specials {
        position: absolute;
        bottom: -10px;
        right: 35%;
        transform: translateX(50%);
        border: 1px solid #fff;
        border-radius: 10px;
        padding: 5px 10px;
        color: #fff;
        text-decoration: none;
        font-family: iran-sans;
        display: flex;
        align-items: center;
        font-size: 15px;

        .arrow {
          color: #fff;
          font-size: 15px;
        }
      }
    }
    .left {
      width: 80%;
    }
  }
  .futures {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .Warranty {
      height: 300px;
      flex: 1;
      background: url(../../assets/images/warranty.png) no-repeat;
      background-position: center;
    }
    .lottery {
      height: auto;
      flex: 1;
      margin: 0.7em auto;
      background-position: center;
      display: flex;
      justify-content: center;

      img {
        width: fit-content;
      }
    }
  }
  .off-products {
    padding: 1em;
    width: 99%;
    margin: 10px auto;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: -5px 1px 34px 5px rgba(109, 109, 109, 0.19);
    background-color: #fff;

    .section-title {
      width: 100%;
      font-family: iran-sans-b;
      padding: 0 0 0.5em 0;
      color: #6a6ca0;
      border-bottom: 1px solid #ddd;
    }
  }
  .popular-products {
    padding: 1em;
    width: 99%;
    margin: 30px auto 10px auto;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: -5px 1px 34px 5px rgba(109, 109, 109, 0.19);
    background-color: #fff;

    .section-title {
      width: 100%;
      font-family: iran-sans-b;
      color: #6a6ca0;
      padding: 0 0 0.5em 0;
      border-bottom: 1px solid #ddd;
    }
  }
  .support {
    width: 99%;
    margin: 20px auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    background-color: #02a1a7;
    padding: 1em;
    border-radius: 10px;

    .payment-icon {
      position: relative;
      top: 4px;
    }
  }
}

#profile {
  display: none;
}

@media (max-width: 670px) {
  .bottom {
    display: none !important;
  }
  .search-mobile {
    display: flex !important;
    position: relative;

    input {
      width: 100%;
      padding: 1em 0.7em;
      font-family: iran-sans-r;
      border-radius: 5px;
      border: 1px solid rgb(224, 224, 224);
      margin: 1em 0.2em;
    }
    svg {
      position: absolute;
      left: 15px;
      top: 30px;
      color: rgb(153, 153, 153);
    }
  }
  header .top .left {
    width: 70%;
    justify-content: flex-start !important;
    flex-direction: row-reverse;
  }
  header .top .left .go-to-bascket {
    margin-left: 0 !important;
    margin-right: 10px;
  }
  header .top .right .input {
    display: none;
  }
  footer .content .banner {
    flex-direction: column;
  }
  footer .content .head .right {
    display: none !important;
  }
  footer .content .head .left {
    width: 100%;
    margin: 0 0 2em 0;
  }
  footer .content .banner .right {
    flex-direction: column;
    margin: 0.5em 0 2em;
  }
  footer .content .banner .right .title {
    font-size: 14px;
    margin: 0.5em 0;
  }
  footer .content .banner .left {
    flex-wrap: wrap;
    justify-content: center !important;
  }
  footer .content .banner .left a {
    padding: 0.5em 0;
    display: inline-flex;
  }
  footer .content .info {
    flex-direction: column;
  }
  footer .content .info .right {
    width: 97% !important;
    margin-right: 0 !important;
    padding: 0 0 1em 0;
  }
  footer .content .info .right .description {
    font-size: 13px !important;
  }
  footer .content .copy-right div {
    font-size: 11px !important;
  }
  .product-card {
    width: 180px !important;
  }
  .product-card a {
    width: 100% !important;
  }
  .product-card .picture {
    width: 100% !important;
    margin: 0 auto;
    background-size: cover;
    height: 180px !important;
  }
  .product-card .title {
    font-size: 14px !important;
  }
  .product-card .off {
    font-size: 13px !important;
  }
  .product-card .off-money {
    font-size: 13px !important;
  }
  .index-main .special-offers .right .specialOfferImage {
    right: -10px;
    width: 80px;
    top: 15px;
  }
  .index-main .special-offers .right .all-specials {
    display: none;
  }

  .signInCard {
    flex-direction: column;
    width: 90% !important;

    .signInPic {
      display: none;
    }
    .content {
      width: 100% !important;
    }
  }
  .forgetPassword {
    flex-direction: column;
    width: 90% !important;

    .signInPic {
      display: none;
    }
    .content {
      width: 100% !important;
    }
  }
  .index-main .futures {
    flex-direction: column;
  }
  .index-main .futures .lottery img {
    width: 100%;
  }
}
