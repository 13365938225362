@font-face {
  font-family: irancell;
  src: url(../../assets//fonts/irancell.otf);
}

body {
  font-family: irancell;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

.index-notFound {
  display: flex;
  flex-direction: column;
  font-family: iran-sans-b;
  margin: 0 auto;
  max-width: 1367px;
  padding: 1em;
  width: 100%;

  img {
    margin: 0 auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  p {
    color: #753517;
    font-size: 18px;
    text-align: center;
  }

  h4 {
    font-family: irancell;
  }

  a {
    border: 0;
    border-radius: 5px;
    color: #753517;
    cursor: pointer;
    font-family: iran-sans-b;
    font-size: 14px;
    margin: 0.7em auto;
    padding: 0.5em;
    text-align: center;
    width: 300px;
  }

  span {
    font-family: irancell;
  }

  .instagram-content {
    font-family: iran-sans-r;
    font-size: 16px;
    margin: 1em auto;
    text-align: justify;
    width: 75%;
  }

  .instagramKitLink {
    background-color: #fff;
    font-size: 18px;
    position: relative;
    top: 162px;
    z-index: 1;
  }

  .instagramKit {
    position: relative;
    top: -50px;
  }
}

.app-download {
  margin: 1em;
}

.borderContainer {
  font-family: iransansb;
  border: 2px solid #753517;
  border-radius: 1em;
  margin-bottom: 20px;
  padding: 50px;
  width: 100%;
}

.downloaddiv {
  width: 100%;
  margin-top: 10px;

}

#DownlnloadImg {
  width: 130px ;
}

.grop {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 50px;
  padding-top: 30px;
  width: 100%;

  .left-s {
    color: #000;
    align-items: center;
    display: flex !important;
    flex-direction: column;
    justify-content: center !important;
    position: relative;
    width: 100%;
    text-align: center !important;
    width: 50%;

  }

  .right-s {
    direction: rtl;
    color: #000;
    display: flex !important;
    flex-direction: column;
    justify-content: start !important;
    padding-top: 50px;
    text-align: justify;
    text-justify: inter-word;
    width: 50%;
  }
}

.qr-block {
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 0 5px hsla(0, 0%, 76%, 0.5);
  margin: 1em auto;
}