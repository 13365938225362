.app-download {
  margin: 1em;
}
.borderContainer{ 
  margin-bottom: 20px;
  width: 100%;
  border-radius: 1em;
  border: 2px solid #753517;
  padding:50px ;
}
.grop{
  width: 100%;
  display: flex;
  padding-top: 30px;
  padding-bottom: 50px;
  flex-wrap: wrap;
  .left-s{
    position: relative;
    color: #000;
      width: 50%;
      text-align: center !important;
      display: flex !important;
      flex-direction: column;
      justify-content: center !important;
      .bannerimage{
        max-width: 400px;
      }
  }
  .right-s{
    text-align: justify;
    text-justify: inter-word;
    padding-top: 50px;
    color: #000;
    width: 50%;
      display: flex !important;
      flex-direction: column;
      justify-content: start !important;
  }
}
.paddingimg{
  padding-bottom: 60px;
}
.qr-block {
  background-color: #fff;
  margin: 1em auto;
  box-shadow: 0 0 5px rgba(194, 194, 194, 0.5);
  border-radius: 15px;
}
@media (max-width: 500px) {
  .right-s{
    padding-top: 0px !important;
    padding-bottom: 20px !important;
    width: 100% !important;
  }
  .left-s{
    width: 100% !important;
  }
}