@media (max-width : 600px) {
    .instagram-content {
        width: 95% !important;

    }

    .app-download {
        padding: 5px 30px !important;
    }

    .borderContainer {
        padding: 15px !important;
    }
}

#insta {
    text-align: right;
}