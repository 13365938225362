.Toastify__toast-container--bottom-right {
  width: 300px !important;
  bottom: 20px !important;
}

.index-basket {
  width: 100%;
  max-width: 1367px;
  margin: 0 auto;
  background-color: #f8fdff;
  padding: 1em;
  display: flex;
  font-family: iran-sans-b;
  display: flex;
  flex-direction: column;

  .basket-card {
    width: 100%;
    background-color: #fff;
    box-shadow: 0 0 5px rgba(194, 194, 194, 0.5);
    padding: 1em;
    border-radius: 10px;
    color: #1d4c61;
    margin: 1em 0;
    display: flex;
    flex-direction: column;
  }

  .row-pay {
    width: 100%;
    background-color: #f8fdff;

    .title {
      font-size: 14px;
      text-align: start;
      padding: 0 0.5em;
    }
    .way-pay {
      font-size: 14px;
      margin: 0.8em 0 0.8em 0.8em;
      color: #1d4c61;

      input {
        margin: 0 0.5em;
      }
    }
  }

  table {
    overflow: hidden;
    tr {
      border-top: 1px solid rgba(0, 0, 0, 0.5);
      margin: 1em 0;
      height: 80px;
    }
    hr {
      width: 500%;
      margin: 1em 0;
    }
  }

  .row-title {
    display: flex;
    justify-content: space-around;
  }

  .product-title {
    display: flex;
    align-items: center;
    font-size: 14px;

    svg {
      cursor: pointer;
    }
    img {
      width: 100px;
      border: 1px solid rgb(185, 185, 185);
      border-radius: 5px;
      margin: 0 0.5em;
    }
    h2 {
      font-size: 17px;
      color: #808080;
    }
  }
  .price {
    text-align: center;
  }
  .counter {
    text-align: center;

    img {
      cursor: pointer;
    }
    .count {
      position: relative;
      top: -14px;
      margin: 0 0.5em;
    }
  }
  .sumPrice {
    text-align: center;
  }
  .row {
    display: flex;
    justify-content: space-between;

    .sumPrice {
      position: relative;
      left: 4em;
      align-items: center;
      display: flex;
    }
  }
  .copoun {
    width: 50%;

    input {
      padding: 0.5em 1em;
      width: 55%;
      border: 1px solid rgb(185, 185, 185);
      border-radius: 5px;
      font-family: iran-sans-b;
      margin: 0 0 0 0.5em;
    }
  }
  button {
    padding: 0.6em 1.5em;
    font-family: iran-sans-b;
    border: 0;
    border-radius: 5px;
    background-color: #00aeb4;
    color: #fff;
    cursor: pointer;
  }
  .otherProduct {
    background-color: #fff;
    color: #00aeb4;
    border: 1px solid #00aeb4;
    padding: 0.5em 5em;
    margin: 0 0.5em;
  }

  .tranport {
    margin: 2em 0;
  }
}

@media (max-width: 670px) {
  .index-basket table hr {
    width: 700%;
  }
  .basket-card {
    table {
      th {
        font-size: 14px;
      }
    }
  }
  .index-basket .product-title img {
    display: none;
  }
  .index-basket .product-title svg {
    display: none;
  }
  .index-basket .product-title h2 {
    font-size: 14px;
  }
  .index-basket .product-title span {
    font-size: 12px;
  }
  .index-basket .price {
    font-size: 14px;
  }
  .index-basket .sumPrice {
    font-size: 14px;
  }
  .index-basket .counter {
    display: flex;
    position: relative;
    top: 25px;
  }
  .index-basket .counter .count {
    top: 5px;
  }
  .index-basket .copoun {
    width: 60%;
  }
  .index-basket .row .sumPrice {
    left: 0em;
  }
  .index-basket .otherProduct {
    margin: 0.5em 0;
  }
  .index-basket .tranport {
    font-size: 14px;
  }
}
