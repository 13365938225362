.garantySubmit {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  .title {
    text-align: center;
    padding: 15px;
  }
  .qrCodeContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    border: 1px solid gray;
  }
  .modalContainer {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    height: 100vh;
    background: #fff;
    width: 100vw;
    padding: 15px;
    overflow: hidden;
    z-index: 100;
    .modalSelf {
      width: 100%;
      border: 1px solid #8080806b;
      border-radius: 15px;
      padding: 15px 10px;
      overflow-y: scroll;
      height: 80%;
      max-height: 80%;
      padding: 15px;
      img {
        width: 100%;
        border-radius: 15px;
      }
      hr {
        margin: 10px 0;
        border-color: #cccccc80;
      }
    }
    .actions {
      display: flex;
      justify-content: space-evenly;
      margin-top: 10px;
      button {
        background: #fff;
        border: 1px solid #00aeb4;
        color: #00aeb4;
        border-radius: 10px;
        width: 30%;
        padding: 10px;
      }
      .submit {
        width: 60%;
        background: #00aeb4;
        color: #fff;
      }
    }
  }
}
