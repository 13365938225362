.index-aboutUs {
  width: 100%;
  max-width: 1367px;
  margin: 0 auto;
  background-color: #f8fdff;
  padding: 1em;
  display: flex;
  flex-direction: column;
  font-family: iran-sans-b;

  .agentBanner {
    width: 100%;
  }
  .agent-about {
    display: flex;
    background-color: #fff;
    box-shadow: 0 0 5px rgba(194, 194, 194, 0.5);
    border-radius: 10px;
  }
  .content {
    width: 70%;
    padding: 1em;

    h1 {
      font-size: 16px;
      padding: 1em 0;
    }
    p {
      font-family: iran-sans-r;
      font-size: 14px;
      text-align: justify;
    }
  }
  .swiper-pointer-events {
    width: 100%;
  }
  .swiper-slide {
    img {
      width: 100%;
    }
  }
}

@media (max-width: 670px) {
  .index-agent .agent-about {
    flex-direction: column;
  }
  .index-agent .content {
    width: 100%;
  }
  .agent-about .image img {
    width: 100%;
  }
  .index-agent .agent-list table hr {
    width: 920%;
  }
  .agent-about {
    flex-direction: column;
  }
  .index-aboutUs .content {
    width: 100%;
  }
}